const useStateManifest = async (props: {
  page?: number;
  status: IPChat.StateStatus | IPChat.StateStatus[];
}) => {
  if (!props?.page) {
    props = {
      ...props,
      page: 1,
    }
  }

  const { data } = await window.axios.get<
    {
      data: IPChat.State[]
      total: number;
      current_page: number;
      last_page: number;
    }
  >(
    route('v2.state.generateManifest', {
      _query: { page: props.page, status: props.status, limit: 1000 },
    }),
  )

  return { data }
}

export default useStateManifest
