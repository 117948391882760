const useAssignAttendant = async (props: { stateId: number }) => {
  const { stateId } = props
  return await window.axios.post(
    route('v2.state.assignAttendant', { state: stateId }),
    undefined,
    {
      'axios-retry': {
        retries: 0,
      },
    }
  )
}

export default useAssignAttendant
