const useContactManifest = async (props: {
  contactIds: number[];
}) => {
  const { contactIds } = props

  const { data } = await window.axios.post<IPChat.Contact[]>(
    route('v2.contact.generateManifest'),
    {
      contactIds
    }
  )

  return { data }

}

export default useContactManifest
