export const markAsRead = (state: number) =>
  window.axios.patch(route('v2.state.markAsRead', { state }))
export const markGroupAsRead = (
  groupId: number,
  lastMessageIdOrKey: number | string
) =>
  window.axios.patch(
    route('v2.state.markGroupAsRead', { groupId, lastMessageIdOrKey })
  )
export const markAsUnread = (state: number) =>
  window.axios.patch(route('v2.state.markAsUnread', { state }))
