interface IEraseMessage {
  messageKey: string
  stateId: number
}

const useEraseMessage = async (props: IEraseMessage) => {
  await window.axios.delete(route('v2.message.revokeMessage'), {
    'axios-retry': {
      retries: 0,
    },
    data: {
      messageKey: props.messageKey,
      stateId: props.stateId,
    },
  })
}

export default useEraseMessage
