const useGroupPendings = async (props: { stateIds: number[] }) => {
  const { stateIds } = props

  const { data } = await window.axios.post<{
    id: number;
    was_read: 0 | 1
  }[]>(
    route('v2.state.groupPendingToRead'),
    {
      stateIds
    }
  )

  return { data }
}

export default useGroupPendings
